/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { Suspense } from 'react';
import { useLoader, useResource } from 'react-three-fiber';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import LoadingSphere from '../../../../components/3d/LoadingSphere';
import { OrbitControls } from '../../../../components/3d/OrbitControls';
import { PolygonalModels } from '../../../../stores';
import { useSavedCamera } from '../../../../hooks/useSavedCamera';

export const Asset = ({ url, sceneData, wireframe, flat, scale, rotation, position, normals, vertexNormalsColor }) => {
  const gltf = useLoader(GLTFLoader, url, (loader) => {
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('/draco-gltf/');
    loader.setDRACOLoader(dracoLoader);
  });

  const Bunny = ({ isWireframe, isFlat, isNormals }) => {
    const [ref, obj] = useResource();

    return (
      <scene name="OSG_Scene">
        <object3D name="RootNode_(gltf_orientation_matrix)" rotation={[-1.5707963267948963, 0, 0]}>
          <object3D name="RootNode_(model_correction_matrix)" rotation={[1.5707963267948963, 0, 0]}>
            <object3D name="Geode">
              <mesh ref={ref} scale={scale} position={position} rotation={rotation}>
                <bufferGeometry attach="geometry" {...gltf.__$[4].geometry} />
                <meshStandardMaterial
                  attach="material"
                  {...gltf.__$[4].material}
                  name="Scene_-_Root"
                  flatShading={isFlat}
                  wireframe={isWireframe}
                  color={isWireframe ? sceneData.wireframeColor : sceneData.modelColor}
                />
              </mesh>
              {isNormals && obj ? <vertexNormalsHelper args={[obj, 0.05, vertexNormalsColor]} /> : null}
            </object3D>
          </object3D>
        </object3D>
      </scene>
    );
  };

  return (
    <group>
      {!flat && <Bunny isWireframe={false} isFlat={false} isNormals={normals} />}
      {flat && <Bunny isWireframe={false} isFlat={true} isNormals={normals} />}

      {wireframe && <Bunny isWireframe={true} isFlat={false} isNormals={normals} />}
    </group>
  );
};

const BunnyObject = () => {
  const bunny = PolygonalModels.useStore((state) => state.complex.models[PolygonalModels.EComplexModel.BUNNY]);
  const scene = PolygonalModels.useStore((state) => state.scene);
  const saveCam = PolygonalModels.useStore((state) => state.actions.saveComplexCamera);

  useSavedCamera({ object: bunny, saveFunc: saveCam });

  return (
    <Suspense fallback={<LoadingSphere />}>
      <>
        <Asset
          sceneData={scene}
          url={bunny.file}
          wireframe={bunny.isWireframe}
          flat={bunny.isFlat}
          scale={bunny.scale}
          rotation={bunny.rotation}
          position={bunny.position}
          normals={bunny.isFaceNormals}
          vertexNormalsColor={scene.vertexNormalsColor}
        />
        <OrbitControls enablePan={true} />
      </>
    </Suspense>
  );
};

export default BunnyObject;

import React from 'react';
import { Matrix4, Vector3 } from 'three';
import { StoreApi } from 'zustand';

interface UseAnimationStepFalseNormalsReturn {
  animateFalseNormalAnimationStep: (scaleMatrix: Matrix4, ref: any) => void;
}

export const useAnimationStepFalseNormals = (
  animationStore: StoreApi<Record<string | number | symbol, any>>,
): UseAnimationStepFalseNormalsReturn => {
  const animate = React.useCallback(
    (scaleMatrix: Matrix4, ref: any) => {
      //@ts-ignore
      const oldVertices = animationStore.getState().vertices;

      //@ts-ignore
      const currentVertices: Vector3[] = ref.current.geometry.vertices;

      for (let i = 0, il = currentVertices.length; i < il; i++) {
        const newVertex = oldVertices[i].clone();
        newVertex.applyMatrix4(scaleMatrix);
        currentVertices[i].copy(new Vector3(newVertex.x, newVertex.y, newVertex.z));
      }

      //@ts-ignore
      ref.current.geometry.verticesNeedUpdate = true;
      //@ts-ignore
      ref.current.geometry.normalsNeedUpdate = true;
    },
    [animationStore],
  );

  return { animateFalseNormalAnimationStep: animate };
};

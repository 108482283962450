import React from 'react';
import { extend } from 'react-three-fiber';
import { FaceNormalsHelper } from 'three/examples/jsm/helpers/FaceNormalsHelper';
import { VertexNormalsHelper } from 'three/examples/jsm/helpers/VertexNormalsHelper';

extend({ VertexNormalsHelper, FaceNormalsHelper });

export interface HelperInput {
  visible: boolean;
  mesh: any;
  length: number;
  color: number;
}

/**
 * Displays face normals as lines of the referenced mesh
 */
export const FaceNormals = React.forwardRef(({ visible, mesh, length, color }: HelperInput, ref) => {
  return (
    //@ts-ignore
    <faceNormalsHelper ref={ref} visible={visible} args={[mesh, length, color, 1]} />
  );
});
FaceNormals.displayName = 'FaceNormals';

/**
 * Displays vertex normals as lines of the referenced mesh
 */
export const VertexNormals = React.forwardRef(({ visible, mesh, length, color }: HelperInput, ref) => {
  return (
    //@ts-ignore
    <vertexNormalsHelper ref={ref} visible={visible} args={[mesh, length, color, 1]} />
  );
});
VertexNormals.displayName = 'VertexNormals';

import React from 'react';
import { useResource, useUpdate } from 'react-three-fiber';
import * as THREE from 'three';

import { Illumination } from '../../../../../stores';

export const SpotLight: React.FC = () => {
  const lightData = Illumination.useStore((store: Illumination.Store) => store.state.lights['spot']);
  const [ref, light] = useResource<THREE.SpotLight>();

  const spotlightHelperRef = useUpdate(
    (spotlightHelper: any) => {
      spotlightHelper.update();
    },
    [lightData, lightData.distance], // execute only if these properties change
  );

  return (
    <>
      <spotLight
        name="spotlight_1"
        intensity={lightData.intensity}
        distance={lightData.distance}
        penumbra={lightData.penumbra}
        angle={lightData.angle}
        decay={lightData.decay}
        castShadow
        position={lightData.position as [number, number, number]}
        ref={ref}
        color={(lightData.color as unknown) as THREE.Color}
      />
      {light && lightData.showHelper && <spotLightHelper ref={spotlightHelperRef} args={[ref.current, 0x00ff00]} />}
      {/* {light && lightData.showHelper && <cameraHelper ref={camHelperRef} args={[ref.current.shadow.camera]} />} */}
    </>
  );
};

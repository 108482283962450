/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { Suspense } from 'react';
import { useLoader } from 'react-three-fiber';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import LoadingSphere from '../../../../components/3d/LoadingSphere';
import { OrbitControls } from '../../../../components/3d/OrbitControls';
import { PolygonalModels } from '../../../../stores';
import { useSavedCamera } from '../../../../hooks/useSavedCamera';

export const Ship = ({ url, wireframe, scale, position, wireframeLineColor, wireframeBodyColor }) => {
  const gltf = useLoader(GLTFLoader, url, (loader) => {
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('/draco-gltf/');
    loader.setDRACOLoader(dracoLoader);
  });

  return (
    <group scale={scale} position={position}>
      <scene name="OSG_Scene">
        <object3D name="RootNode_(gltf_orientation_matrix)" rotation={[-1.5707963267948963, 0, 0]}>
          <object3D
            name="RootNode_(model_correction_matrix)"
            position={[0.1427582949399948, 0, -22325.513671875]}
            rotation={[-3.141592653589793, 0, -3.141592653589793]}
          >
            <object3D name="ShipFBX" rotation={[-Math.PI, 0, 0]}>
              <object3D name="RootNode">
                <object3D name="Ship" position={[0, 0, 0]}>
                  {/* ----------------------------------------------------------------------------------------- */}
                  <mesh name={'WRAPPER_Ship_rug_0'}>
                    <mesh name="Ship_rug_0" visible={!wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[6].geometry} />
                      <meshBasicMaterial
                        attach="material"
                        {...gltf.__$[6].material}
                        name="material_2"
                        wireframe={wireframe}
                      />
                    </mesh>
                    <mesh name="Ship_rug_0_WIREFRAME" visible={wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[6].geometry} />
                      <meshBasicMaterial
                        attach="material"
                        name="material_2"
                        wireframe={wireframe}
                        color={wireframeLineColor}
                      />
                    </mesh>
                    <mesh name="Ship_rug_0_WIREFRAME_BODY" visible={wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[6].geometry} />
                      <meshStandardMaterial attach="material" name="material_2" color={wireframeBodyColor} />
                    </mesh>
                  </mesh>
                  {/* ----------------------------------------------------------------------------------------- */}
                  <mesh name={'WRAPPER_Ship_wood_trims_0'}>
                    <mesh name="Ship_wood_trims_0" visible={!wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[7].geometry} />
                      <meshBasicMaterial attach="material" {...gltf.__$[7].material} name="wood_trims" />
                    </mesh>
                    <mesh name="Ship_wood_trims_0_WIREFRAME" visible={wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[7].geometry} />
                      <meshBasicMaterial
                        attach="material"
                        name="wood_trims"
                        wireframe={true}
                        color={wireframeLineColor}
                      />
                    </mesh>
                    <mesh name="Ship_wood_trims_0_WIREFRAME_BODY" visible={wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[7].geometry} />
                      <meshStandardMaterial attach="material" name="wood_trims" color={wireframeBodyColor} />
                    </mesh>
                  </mesh>
                  {/* ----------------------------------------------------------------------------------------- */}
                  <mesh name={'WRAPPER_Ship_wood_planks_0'}>
                    <mesh name="Ship_wood_planks_0" visible={!wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[8].geometry} />
                      <meshBasicMaterial attach="material" {...gltf.__$[8].material} name="wood_planks" />
                    </mesh>
                    <mesh name="Ship_wood_planks_0_WIREFRAME" visible={wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[8].geometry} />
                      <meshBasicMaterial
                        attach="material"
                        name="wood_planks_wireframe_mat"
                        wireframe={true}
                        color={wireframeLineColor}
                      />
                    </mesh>
                    <mesh name="Ship_wood_planks_0_WIREFRAME_BODY" visible={wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[8].geometry} />
                      <meshStandardMaterial
                        attach="material"
                        name="wood_planks_wireframe_mat"
                        color={wireframeBodyColor}
                      />
                    </mesh>
                  </mesh>

                  {/* ----------------------------------------------------------------------------------------- */}

                  <mesh name={'WRAPPER'}>
                    <mesh name="Ship_wood_painted_0" visible={!wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[9].geometry} />
                      <meshBasicMaterial attach="material" {...gltf.__$[9].material} name="wood_painted" />
                    </mesh>
                    <mesh name="NAME_WIREFRAME" visible={wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[9].geometry} />
                      <meshBasicMaterial attach="material" name="" wireframe={true} color={wireframeLineColor} />
                    </mesh>
                    <mesh name="NAME_WIREFRAME_BODY" visible={wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[9].geometry} />
                      <meshStandardMaterial attach="material" name="" color={wireframeBodyColor} />
                    </mesh>
                  </mesh>
                  {/* ----------------------------------------------------------------------------------------- */}

                  <mesh name={'WRAPPER'}>
                    <mesh name="Ship_sails_0" visible={!wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[10].geometry} />
                      <meshBasicMaterial attach="material" name="sails" {...gltf.__$[10].material} />
                    </mesh>
                    <mesh name="NAME_WIREFRAME" visible={wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[10].geometry} />
                      <meshBasicMaterial attach="material" name="" wireframe={true} color={wireframeLineColor} />
                    </mesh>
                    <mesh name="NAME_WIREFRAME_BODY" visible={wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[10].geometry} />
                      <meshStandardMaterial attach="material" name="" color={wireframeBodyColor} />
                    </mesh>
                  </mesh>
                  {/* ----------------------------------------------------------------------------------------- */}

                  <mesh name={'WRAPPER'}>
                    <mesh name="Ship_building_mats_0" visible={!wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[11].geometry} />
                      <meshBasicMaterial attach="material" {...gltf.__$[11].material} name="building_mats" />
                    </mesh>
                    <mesh name="NAME_WIREFRAME" visible={wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[11].geometry} />
                      <meshBasicMaterial attach="material" name="" wireframe={true} color={wireframeLineColor} />
                    </mesh>
                    <mesh name="NAME_WIREFRAME_BODY" visible={wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[11].geometry} />
                      <meshStandardMaterial attach="material" name="" color={wireframeBodyColor} />
                    </mesh>
                  </mesh>
                  {/* ----------------------------------------------------------------------------------------- */}

                  <mesh name={'WRAPPER'}>
                    <mesh name="Ship_emissive_0" visible={!wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[12].geometry} />
                      <meshBasicMaterial attach="material" {...gltf.__$[12].material} name="emissive" />
                    </mesh>
                    <mesh name="NAME_WIREFRAME" visible={wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[12].geometry} />
                      <meshBasicMaterial attach="material" name="" wireframe={true} color={wireframeLineColor} />
                    </mesh>
                    <mesh name="NAME_WIREFRAME_BODY" visible={wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[12].geometry} />
                      <meshStandardMaterial attach="material" name="" color={wireframeBodyColor} />
                    </mesh>
                  </mesh>
                  {/* ----------------------------------------------------------------------------------------- */}

                  <mesh name={'WRAPPER'}>
                    <mesh name="Ship_foliage_0" visible={!wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[13].geometry} />
                      <meshBasicMaterial attach="material" {...gltf.__$[13].material} name="foliage" />
                    </mesh>
                    <mesh name="NAME_WIREFRAME" visible={wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[13].geometry} />
                      <meshBasicMaterial attach="material" name="" wireframe={true} color={wireframeLineColor} />
                    </mesh>
                    <mesh name="NAME_WIREFRAME_BODY" visible={wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[13].geometry} />
                      <meshStandardMaterial attach="material" name="" color={wireframeBodyColor} />
                    </mesh>
                  </mesh>
                  {/* ----------------------------------------------------------------------------------------- */}

                  <mesh name={'WRAPPER'}>
                    <mesh name="Ship_foliage_02_0" visible={!wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[14].geometry} />
                      <meshBasicMaterial attach="material" {...gltf.__$[14].material} name="foliage_02" />
                    </mesh>
                    <mesh name="NAME_WIREFRAME" visible={wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[14].geometry} />
                      <meshBasicMaterial attach="material" name="" wireframe={true} color={wireframeLineColor} />
                    </mesh>
                    <mesh name="NAME_WIREFRAME_BODY" visible={wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[14].geometry} />
                      <meshStandardMaterial attach="material" name="" color={wireframeBodyColor} />
                    </mesh>
                  </mesh>
                  {/* ----------------------------------------------------------------------------------------- */}

                  <mesh name={'WRAPPER'}>
                    <mesh name="Ship_sand_caustics_0" visible={!wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[15].geometry} />
                      <meshBasicMaterial attach="material" {...gltf.__$[15].material} name="sand_caustics" />
                    </mesh>
                    <mesh name="NAME_WIREFRAME" visible={wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[15].geometry} />
                      <meshBasicMaterial attach="material" name="" wireframe={true} color={wireframeLineColor} />
                    </mesh>
                    <mesh name="NAME_WIREFRAME_BODY" visible={wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[15].geometry} />
                      <meshStandardMaterial attach="material" name="" color={wireframeBodyColor} />
                    </mesh>
                  </mesh>
                  {/* ----------------------------------------------------------------------------------------- */}

                  <mesh name={'WRAPPER'}>
                    <mesh name="Ship_sand_tile_0" visible={!wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[16].geometry} />
                      <meshBasicMaterial attach="material" {...gltf.__$[16].material} name="sand_tile" />
                    </mesh>
                    <mesh name="NAME_WIREFRAME" visible={wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[16].geometry} />
                      <meshBasicMaterial attach="material" name="" wireframe={true} color={wireframeLineColor} />
                    </mesh>
                    <mesh name="NAME_WIREFRAME_BODY" visible={wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[16].geometry} />
                      <meshStandardMaterial attach="material" name="" color={wireframeBodyColor} />
                    </mesh>
                  </mesh>
                  {/* ----------------------------------------------------------------------------------------- */}

                  <mesh name={'WRAPPER'}>
                    <mesh name="Ship_rock_tile_0" visible={!wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[17].geometry} />
                      <meshBasicMaterial attach="material" {...gltf.__$[17].material} name="rock_tile" />
                    </mesh>
                    <mesh name="NAME_WIREFRAME" visible={wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[17].geometry} />
                      <meshBasicMaterial attach="material" name="" wireframe={true} color={wireframeLineColor} />
                    </mesh>
                    <mesh name="NAME_WIREFRAME_BODY" visible={wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[17].geometry} />
                      <meshStandardMaterial attach="material" name="" color={wireframeBodyColor} />
                    </mesh>
                  </mesh>
                  {/* ----------------------------------------------------------------------------------------- */}

                  <mesh name={'WRAPPER'}>
                    <mesh name="Ship_rocks_unique_0" visible={!wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[18].geometry} />
                      <meshBasicMaterial attach="material" {...gltf.__$[18].material} name="rocks_unique" />
                    </mesh>
                    <mesh name="NAME_WIREFRAME" visible={wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[18].geometry} />
                      <meshBasicMaterial attach="material" name="" wireframe={true} color={wireframeLineColor} />
                    </mesh>
                    <mesh name="NAME_WIREFRAME_BODY" visible={wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[18].geometry} />
                      <meshStandardMaterial attach="material" name="" color={wireframeBodyColor} />
                    </mesh>
                  </mesh>
                  {/* ----------------------------------------------------------------------------------------- */}
                  <mesh name={'WRAPPER'}>
                    <mesh name="Ship_water_light_0" visible={!wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[19].geometry} />
                      <meshBasicMaterial attach="material" {...gltf.__$[19].material} name="water_light" />
                    </mesh>
                    <mesh name="NAME_WIREFRAME" visible={wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[19].geometry} />
                      <meshBasicMaterial attach="material" name="" wireframe={true} color={wireframeLineColor} />
                    </mesh>
                    <mesh name="NAME_WIREFRAME_BODY" visible={wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[19].geometry} />
                      <meshStandardMaterial attach="material" name="" color={wireframeBodyColor} />
                    </mesh>
                  </mesh>
                  {/* ----------------------------------------------------------------------------------------- */}

                  <mesh name={'WRAPPER'}>
                    <mesh name="Ship_bits_bobs_0" visible={!wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[20].geometry} />
                      <meshBasicMaterial attach="material" {...gltf.__$[20].material} name="bits_bobs" />
                    </mesh>
                    <mesh name="NAME_WIREFRAME" visible={wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[20].geometry} />
                      <meshBasicMaterial attach="material" name="" wireframe={true} color={wireframeLineColor} />
                    </mesh>
                    <mesh name="NAME_WIREFRAME_BODY" visible={wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[20].geometry} />
                      <meshStandardMaterial attach="material" name="" color={wireframeBodyColor} />
                    </mesh>
                  </mesh>
                  {/* ----------------------------------------------------------------------------------------- */}
                </object3D>
                <object3D
                  name="sky"
                  position={[0, 0, 595.6158447265625]}
                  scale={[1.3289499282836914, 1.3289499282836914, 0.8719646334648132]}
                >
                  <object3D position={[0, 0, 9633.3212890625]}>
                    <mesh name="sky_sky_0" visible={!wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[23].geometry} />
                      <meshBasicMaterial attach="material" {...gltf.__$[23].material} name="material" />
                    </mesh>
                    <mesh name="NAME_WIREFRAME" visible={wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[23].geometry} />
                      <meshBasicMaterial attach="material" name="" wireframe={true} color={wireframeLineColor} />
                    </mesh>
                    <mesh name="NAME_WIREFRAME_BODY" visible={wireframe}>
                      <bufferGeometry attach="geometry" {...gltf.__$[23].geometry} />
                      <meshStandardMaterial attach="material" name="" color={wireframeBodyColor} />
                    </mesh>
                  </object3D>
                </object3D>
                <object3D name="No_Shad" position={[0, 0.00008165721374098212, 0]}>
                  <mesh name="No_Shad_emissive_0" visible={!wireframe}>
                    <bufferGeometry attach="geometry" {...gltf.__$[25].geometry} />
                    <meshBasicMaterial attach="material" {...gltf.__$[25].material} name="emissive" />
                  </mesh>
                  <mesh name="NAME_WIREFRAME" visible={wireframe}>
                    <bufferGeometry attach="geometry" {...gltf.__$[25].geometry} />
                    <meshBasicMaterial attach="material" name="" wireframe={true} color={wireframeLineColor} />
                  </mesh>
                  <mesh name="NAME_WIREFRAME_BODY" visible={wireframe}>
                    <bufferGeometry attach="geometry" {...gltf.__$[25].geometry} />
                    <meshStandardMaterial attach="material" name="" color={wireframeBodyColor} />
                  </mesh>
                </object3D>
              </object3D>
            </object3D>
          </object3D>
        </object3D>
      </scene>
    </group>
  );
};

const World = () => {
  const world = PolygonalModels.useStore((state) => state.complex.models[PolygonalModels.EComplexModel.WORLD]);
  const saveCam = PolygonalModels.useStore((state) => state.actions.saveComplexCamera);

  useSavedCamera({ object: world, saveFunc: saveCam });

  return (
    <Suspense fallback={<LoadingSphere />}>
      <>
        <Ship
          url={world.file}
          wireframe={world.isWireframe}
          flat={world.isFlat}
          scale={world.scale}
          rotation={world.rotation}
          position={world.position}
          wireframeLineColor={'#ffffff'}
          wireframeBodyColor={'#6fa8dc'}
        />
        <OrbitControls enablePan={true} />
      </>
    </Suspense>
  );
};

export default World;

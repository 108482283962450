import React, { useState } from 'react';
import { useFrame, useResource } from 'react-three-fiber';
import * as THREE from 'three';

import { Illumination } from '../../../../../stores';

export const PointLight: React.FC = () => {
  const lightData = Illumination.useStore((store: Illumination.Store) => store.state.lights['point']);
  const [ref, light] = useResource<THREE.PointLight>();
  const [ref2, light2] = useResource<THREE.PointLight>();

  const [counter, setCounter] = useState(0);
  const [xPos, setXPos] = useState(0);

  useFrame(() => {
    if (lightData.moveLights) {
      const xDelta = Math.sin(counter / 80) * 3;
      setXPos(xDelta);
      setCounter(counter + 1);
    }

    ref.current.position.set(xPos, 2, -1.5);
    ref2.current.position.set(-xPos, 2, 1.5);
  });

  return (
    <>
      <pointLight
        position={[0, 2, -1.5]}
        color={(lightData.color as unknown) as THREE.Color}
        intensity={lightData.intensity}
        distance={lightData.distance}
        decay={lightData.decay}
        ref={ref}
      />
      {light && lightData.showHelper && <pointLightHelper args={[light, 0.1, lightData.color]} />}
      <pointLight
        position={[0, 2, 1.5]}
        color={(lightData.color as unknown) as THREE.Color}
        intensity={lightData.intensity}
        distance={lightData.distance}
        decay={lightData.decay}
        ref={ref2}
      />
      {light2 && lightData.showHelper && <pointLightHelper args={[light2, 0.1, lightData.color]} />}
    </>
  );
};

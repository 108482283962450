import React from 'react';
import { useResource } from 'react-three-fiber';

import { ILLUMINATION_LIGHT_HELPER_COLOR } from '../../../../../constants';
import { Illumination } from '../../../../../stores';
import * as THREE from 'three';

export const DirectionalLight: React.FC = () => {
  const lightData = Illumination.useStore((store: Illumination.Store) => store.state.lights['directional']);
  const [ref, light] = useResource<THREE.DirectionalLight>();

  return (
    <>
      <directionalLight color={(lightData.color as unknown) as THREE.Color} intensity={lightData.intensity} ref={ref} />
      {light && lightData.showHelper && (
        <directionalLightHelper args={[light, 0.25, ILLUMINATION_LIGHT_HELPER_COLOR]} />
      )}
    </>
  );
};
